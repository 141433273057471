@use "../../styles/partials/__mixins" as *;
@use "../../styles/partials/__variables" as *;

.footer-section {
        
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 3rem;
        background-color: $s-black;
        @include tablet {
        align-items: first baseline;
        border-top: 1px solid $p-grey;
        padding: .3rem 0rem .8rem 0rem;
        margin: 0rem 1rem;
        }
        @include desktop {
            padding: 0rem;
            margin: 1.5rem 8rem;
        }
}
.footer-details {
    margin: 1.5rem 0rem 0.5rem 0rem;
    background-color: $s-black;
    @include tablet {
        font-size: 1.8rem;
        margin-top: 1.5rem;
        padding: 0.5rem;
    }
    @include desktop {
        margin-top: 1rem;
        padding-top: 1rem;
    }
}
.footer-icons__container {
    display: flex;
    justify-content: space-around;
    background-color: $s-black;
    position: absolute;
    margin: 0rem;
    @include tablet {
    margin: 0.2rem;
}
}
.footer-icons {
    padding: 0.2rem;
    width: 100%;
    position: relative;
    top: 3rem;
    z-index: 2;
    background-color: transparent;
    @include tablet {
        width: 100%;
        top: 2rem;
        left: 8rem;
    }
    @include desktop {
        width: 1.8rem;
        top: 1.7rem;
    }
}