@use "./_variables" as *;

@font-face {
    font-family: "my-portfolio";
    src: url("../../assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
    font-weight: 700;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "my-portfolio";
    // background-color: #151515;
    color: #FFFFFF;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.38rem;
  }

main {
      background-color: #151515;
}
  

  html {
    background-color: $s-black;
}