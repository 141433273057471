@use "../../styles/partials/__variables" as *;
@use "../../styles/partials/__mixins" as *;

.about-section {
    // margin: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet {
    align-items: first baseline;
    margin-bottom: 3.5rem;
    padding-left: 0.5rem;
    }
    @include desktop {
        padding-left: 3rem;
    }
    @include bigdesktop {
        padding: 0rem 5rem;
    }
}
.about-title {
    margin: 1.5rem 0rem 0.5rem 0rem;
    @include tablet {
        margin-top: 1rem;
        font-size: 1.8rem;
        padding: 0.5rem;
    }
    @include desktop {
        padding-top: 1rem;
    }
}
.icons-container {
    display: flex;
    justify-content: space-around;
    background-color: #151515;
    position: absolute;

}
.icons {
    padding: 0.2rem;
    width: 100%;
    position: relative;
    top: 3rem;
    z-index: 2;
    background-color: transparent;
    @include tablet {
        top: 1.6rem;
        left: 8rem;
    }
    @include desktop {
        width: 1.8rem;
        top: 2rem;
    }
}
.ovals {
    position: absolute;
    top: 5rem;
    left: -10rem;
    @include tablet {
        left: -7.5rem;
        width: 55vw;
        height: 17vw;
        background-color: transparent;
    }
    @include desktop {
        width: 33vw;
        height: 10vw;
        top: 8rem;
        left: 0rem;
        
    }
}
.image {
    width: 12rem;
    height: 24rem;
    margin-bottom: 1rem;
    background-color: #979797;
    background: none;
    z-index: 1;
    @include tablet {
        width: 15rem;
        height: 30rem;
        position: absolute;
        top: 2rem;
        right: 0rem;
    }
    @include desktop {
        width: 19rem;
        height: 35rem;
        top: 0rem;
        right: 4rem;
        background-color: $s-black;
    }
    @include bigdesktop {
        width: 19rem;
        height: 35rem;
        top: 0rem;
        right: 10rem;
        background-color: $s-black;
    }
}
.circle {
 position: absolute;
 background-color: transparent;
 top: -3rem;
 right: 0rem;
 @include desktop {
    top: 26.5rem;
    right: 19.5rem;
    z-index: 1;
 }
 @include bigdesktop {
    top: 26.5rem;
    right: 25.5rem;
    z-index: 1;
 }
}
.presentation-section {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    @include tablet{
    flex-direction: column;
    align-items: first baseline;
    margin-top: 4rem;
    }
    @include desktop {
        flex-direction: row;
        margin-top: 7.4rem;
    }
}
.about-presentation {
    font-size: 2rem;
    @include tablet {
        font-size: 4rem;
        padding-top: 1.5rem;
        background-color: transparent;
        z-index: 1;
    }
    @include desktop {
        font-size: 4.9rem;
    }
}
.about-author {
    font-size: 0.9rem;
    color: $p-grey;
    margin: 1.25rem 0.5rem;
    letter-spacing: 0.01rem;
    @include tablet {
        width: 24rem;
        font-size: 0.9rem;
        letter-spacing: 0.04rem;
        margin-top: 0rem;
        margin-left: 0.5rem;
        text-align: left;
    }
}
.about-contact {
    font-size: 1rem;
    padding: 0.5rem;
    border-bottom: 2px solid $p-green;
    letter-spacing: 0.1rem;
}
.highlight {
    font-size: 2rem;
    border-bottom: 5px solid $p-green;
    margin-left: 0.5rem;
    @include tablet {
        position: relative;
        top: -4rem;
        font-size: 3rem;
        margin-top: 0rem;
        background-color: transparent;
    }
    @include desktop {
        font-size: 3.9rem;
        margin-top: 0rem;
    }
}
.displace {
    position: relative;
    bottom: 0rem;
    left: 0rem;
    @include tablet {
    bottom: 7rem;
    left: 19.5rem;
    }
    @include desktop {
    bottom: 3.95rem;
    left: 0rem;
    }
}