@use "../../styles/partials/__mixins" as *;
@use "../../styles/partials/__variables" as *;

.contact-section {
  padding: 4rem 0.5rem 2rem 0.5rem;
  background-color: $s-black;
  @include tablet {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-top: 3rem;
   padding-bottom: 0rem;
  }
  @include desktop {
    display: flex;
    flex-direction: row;
    padding-top: 0rem 7rem;
    padding-top: 0rem;
  }
}
.contact-section__title {
  font-size: 2rem;
  background-color: $s-black;
  @include tablet {
    font-size: 4rem;
    padding-top: 1.5rem;
    background-color: transparent;
    z-index: 1;
    padding-bottom: 2.5rem;
  }
  @include desktop {
    font-size: 4.9rem;
    padding-top: 0rem;
    padding-bottom: 3.5rem;
  }
  &:hover {
    color: $p-green;
  }
}
.contact-section__message {
  font-size: 0.9rem;
  color: $p-grey;
  margin: 1.25rem 0.5rem;
  letter-spacing: 0.01rem;
  background-color: $s-black;
  text-align: left;
  @include tablet {
    width: 24rem;
    font-size: 0.9rem;
    letter-spacing: 0.04rem;
    margin-top: 0rem;
    margin-left: 0.5rem;
    letter-spacing: 0.04rem;
    text-align: center;
  }
  @include desktop {
    text-align: left;
    margin-right: 0rem;
  }
}
.contact-container {
  display: flex;
  flex-direction: column;
  background-color: $s-black;
  @include desktop {
    margin-right: 0rem;
    width: 50%;
    justify-content: left;
    align-items: first baseline;
  }
}
.contact-section_form {
  display: flex;
  flex-direction: column;
  border: none;
  border-bottom: 1px solid $p-white;
  background-color: $s-black;
  box-sizing: border-box;
  &:active {
    border-color: none;
    background-color: transparent;
  }
  @include tablet {
    width: 61%;
    border: none;
  }
  @include desktop {
    width: 30%;
    padding-top: 3rem;
    
  }
}
.form-values {
  border: none;
  width: 100%;
  color: $p-grey;
  font-size: 1rem;
  text-align: left;
  margin-top: 0.1rem;
  padding: 2rem 1rem 1rem 1rem;
  background-color: $s-black;
  border-bottom: 1px solid $p-white;

  &:focus {
    outline: none;
    border-color: #ccc;
    background-color: transparent;
    }
  
  @include tablet {
    font-size: 0.8rem;
  }
}

.input-message {
    margin-top: 0.1rem;
    padding-left: 1.2rem;
    padding-bottom: 5rem;
    
    &:focus {
      outline: none;
    border-color: #ccc;
    background-color: transparent;
    }
  }
  
.contact-section_form--button {
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0.1rem;
  margin-bottom: 5rem;
  margin-left: 12rem;
  padding: 2rem 0rem 0.5rem 0rem;
  letter-spacing: 0.1rem;
  background-color: $s-black;
  border-bottom: 2px solid $p-green;
  @include tablet {
   font-size: 0.85rem;
   margin-left: 16.3rem;
  }
  @include desktop {
    margin-left: 12.3rem;
  }
}
.contact-section__ovals {
    position: absolute;
    top: 220rem;
    left: -12rem;
    background-color: transparent;
    @include tablet {
      top: 91rem;
      left: -14rem;
    }
    @include desktop {
      top: 151rem;
      left: -9rem;
      width: 500px;
    }
}