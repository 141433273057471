@use "../../styles/partials/__variables" as *;
@use "../../styles/partials/__mixins" as *;

.skills-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $p-white;
    margin: 4rem 1rem 1rem 1rem;
    padding: 3rem 0rem 1.5rem 0rem;
    @include tablet {
        display: grid;
        grid-template: repeat(3, 100px) / repeat(3, 220px);
        justify-items: left;
        align-items: center;
    }
    @include desktop {
      margin: 4rem 8rem 1rem 8rem;
      grid-template: repeat(3, 100px) / repeat(3, 350px);
      justify-content: left;
  }
    @include bigdesktop {
        margin: 4rem 8rem 1rem 8rem;
        grid-template: repeat(3, 125px) / repeat(3, 350px);
        justify-content: left;
    }
}

.skills-section__content {
    font-size: 1.8rem;
    padding-bottom: 1.8rem;

    &:hover {
        color: $p-green;
      }
@include tablet {
    font-size: 1.3rem;
    padding-bottom: 0rem;
    align-items: last baseline;
}
@include desktop{
  font-size: 1.5rem;
}
}
.skills-section__ovals {
    position: absolute;
    top: 73rem;
    left: 12.5rem;
    background-color: transparent;
    @include tablet {
      top: 48rem;
      left: 24.9rem;
      @include dimensions (15rem, 5rem);
    }
    @include desktop {
      top: 49rem;
      left: 51rem;
      @include dimensions (16rem, 7.5rem);
    }
    @include bigdesktop {
        left: 59rem;
       
      }
      @include biggestdesktop {
        left: 83rem;
      }
}